<template>
  <section>
    <loader v-if="loading" />
    <v-card v-else :dark="$dark.get()" min-height="90vh">
      <v-card-title class="header">
        Группы филиалов
        <v-btn class="coupon-btn" @click="modal = true">
          Добавить группу
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div
          v-for="item in GROUPS"
          :key="item.id"
          class="main-content_card d-flex justify-space-between align-center"
        >
          <p>{{ item.name }}</p>
          <div>
            <v-btn icon color="#5D4BD0" @click="openModal(item)">
              <v-icon>mdi-pencil-circle</v-icon>
            </v-btn>
            <v-btn icon color="error" @click="deleteGroup(item.id)">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- Диалог создания/редактирования -->
    <v-dialog
      v-model="modal"
      activator="parent"
      :width="$vuetify.breakpoint.mdAndDown ? '95%' : '60%'"
    >
      <v-card class="modal">
        <v-card-title class="modal_title">
          {{ editMode ? "Изменение" : "Создание" }} группы
        </v-card-title>
        <v-card-text>
          <v-form ref="groupForm" @submit.prevent="false">
            <v-text-field
              v-model="group.name"
              :rules="[$validate.required]"
              color="#5D4BD0"
              label="Название группы"
              required
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="modal_actions">
          <v-btn v-if="editMode" color="#5D4BD0" dark @click="editGroup()"
            >Изменить</v-btn
          >

          <v-btn v-else color="#5D4BD0" dark @click="createGroup()"
            >Создать</v-btn
          >

          <v-btn class="coupon-btn" @click="modal = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Диалог подтверждения удаления -->
    <delete-dialog
      :show="confirmDeleteDialog"
      @close-modal="confirmDeleteDialog = false"
      @delete-modal="confirmDeleteAction()"
    />
  </section>
</template>
  
  <script>
import { mapGetters, mapActions } from "vuex";
import loader from "@/components/Loader";
import DeleteDialog from "@/components/DeleteDialog";
import showMessage from "@/Functions/message";

export default {
  name: "DepartmentGroups",
  components: {
    loader,
    DeleteDialog,
  },
  data() {
    return {
      loading: false,
      modal: false,
      editMode: false,
      group: {
        id: null,
        name: "",
      },
      confirmDeleteDialog: false,
      deletingId: null,
    };
  },
  computed: {
    ...mapGetters({
      GROUPS: "DepartmentGroups/STATE",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      GET_ALL_GROUPS: "DepartmentGroups/GET_ALL_GROUPS",
      ADD_GROUP: "DepartmentGroups/ADD_GROUP",
      EDIT_GROUP: "DepartmentGroups/EDIT_GROUP",
      DELETE_GROUP: "DepartmentGroups/DELETE_GROUP",
    }),
    async fetchData() {
      this.loading = true;
      await this.GET_ALL_GROUPS();
      this.loading = false;
    },
    resetForm() {
      this.editMode = false;
      this.group = { id: null, name: "" };
      this.$refs.groupForm && this.$refs.groupForm.resetValidation();
    },
    createGroup() {
      if (!this.$refs.groupForm.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      this.loading = true;
      this.ADD_GROUP(this.group).then((res) => {
        this.loading = false;
        if (res?.type === "error") {
          showMessage(res.data.message);
        } else {
          showMessage("Группа филиалов добавлена", true);
          this.modal = false;
        }
      });
    },
    openModal(item) {
      this.editMode = true;
      this.group = JSON.parse(JSON.stringify(item));
      this.modal = true;
    },
    editGroup() {
      if (!this.$refs.groupForm.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      this.loading = true;
      this.EDIT_GROUP(this.group).then((res) => {
        this.loading = false;
        if (res?.type === "error") {
          showMessage(res.data.message);
        } else {
          showMessage("Группа филиалов изменена", true);
          this.modal = false;
        }
      });
    },
    deleteGroup(id) {
      this.deletingId = id;
      this.confirmDeleteDialog = true;
    },
    confirmDeleteAction() {
      this.loading = true;
      this.DELETE_GROUP(this.deletingId).then((res) => {
        this.loading = false;
        if (res?.type === "error") {
          showMessage(res.data.message);
        } else {
          showMessage("Группа филиалов удалена", true);
        }
        this.deletingId = null;
        this.confirmDeleteDialog = false;
      });
    },
  },
};
</script>
  
  <style lang="scss">
.main-content_card {
  display: flex;
  gap: 10px;
  padding: 5px 15px;
  background-color: #eeeeee;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  p {
    margin-bottom: 0;
  }
}

.coupon-btn {
  border: 1px solid #5d4bd0;
  color: #5d4bd0 !important;
  border-radius: 7px;
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_actions {
    justify-content: right;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
  